<template>
    <div class="col-12">
        <div class="card">
            <Loader v-model="loading" />
            <HelperDialog v-model="orderRequestHelper" header="Buscar Pedido" :headers="orderHeaders" :rows="entities" @selected="selectOrder" />
            <HelperDialog v-model="labHelper" header="Buscar Direccion" :headers="labHeaders" :rows="labs" @selected="selectLab" />
            <HelperDialog v-model="paymentHelper" header="Condicion de pago" :headers="paymentHeaders" :rows="payments" @selected="selectPayment" />
              <Panel header="Pedido">
                <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @list="openTable" :actions="['new','save','list']" />
                <Panel header="Datos generales">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-4">
                            <label>Folio</label>
                              <div class="p-inputgroup">
                                <InputText v-model="entity.id"/>
                                <Button @click="orderRequestHelper.visible = true" icon="pi pi-search" class="p-button-primary" />   
                                
                              </div>
                        </div>
                        <FormCalendar wrapperClass="field col-4" label="Fecha" :valid="validate.validations.order_date" v-model="entity.order_date"/>
                        <Status :image="status.image" :severity="status.severity" :status="status.status" />                               
                    </div>
                </Panel>
                <br>
                <div class="grid">
                    <div class="col-6">
                        <Fieldset legend="Datos del cliente">
                            <div class="p-fluid formgrid grid">
                                
                                <FormInputText :valid="validate.validations.id_customer" wrapperClass="field col-4" label="Cliente" v-model="entity.id_customer" disabled="true"/>                                
                                <FormInputText wrapperClass="field col-4" label="Nombre" v-model="entity.name" disabled="true"/>
                                <FormInputText wrapperClass="field col-4" label="Direccion" v-model="entity.customerAddress" disabled="true" />
                                <FormInputText wrapperClass="field col-4" label="Colonia" v-model="entity.suburb" disabled="true"/>
                                <FormInputText wrapperClass="field col-4" label="Poblacion" v-model="entity.municipality" disabled="true"/>
                                <FormInputNumber wrapperClass="field col-4" label="Codigo Postal" v-model="entity.postal_code" disabled="true"/>
                                <FormInputText wrapperClass="field col-4" label="Contacto" v-model="entity.contact" disabled="true"/>
                                <FormInputText wrapperClass="field col-4" label="Condicion de pago" v-model="entity.payment_condition" disabled="true" 
                                @search="(paymentHelper.visible = true)" :search="true" :tooltip="'Dar de alta en esta ruta: Catalogos/Comercial/Condicion de Pago'"/>
                            </div>
                        </Fieldset>
                    </div>
    
                    <div class="col-6">
                        <Fieldset legend="Datos Pedido">
                            <div class="p-fluid formgrid grid">
                                <FormInputText wrapperClass="field col-4" label="LAB" :valid="validate.validations.id_lab" v-model="entity.id_lab" 
                                :tooltip="'Dar de alta en esta ruta: Catalogos/Compras/LAB'" @search="(labHelper.visible= true)"  :search="true"/>
                                <FormInputText wrapperClass="field col-8" label="Direccion" v-model="entity.labAddress"/>
                            </div>
        
                            <div class="p-fluid formgrid grid">
                                <FormInputText wrapperClass="field col-3" label="Orden de compra" v-model="entity.id_oc" />
                                <FormInputNumber wrapperClass="field col-3" label="Descuento 1"  prefix="%" v-model="entity.discount1"/>
                                <FormInputNumber wrapperClass="field col-3" label="Descuento 2"  prefix="%" v-model="entity.discount2"/>
                                <FormInputNumber wrapperClass="field col-3" label="Descuento 3"  prefix="%" v-model="entity.discount3"/>
                                <FormInputText wrapperClass="field col-4" label="Datos Embarque" v-model="entity.shipment_data"/>
                                <FormInputText wrapperClass="field col-4" label="Datos Fletes" v-model="entity.freight_data"/>
                                <FormInputNumber :mode="'currency'" :min="0" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-4" label="T.Cambio" v-model="entity.exchange_rate" />
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <Fieldset legend="Productos / Servicios">
                  <div class="p-fluid formgrid grid">
                    <div class="col-12">
                        <BasicDatatable 
                        @deleted="deleteItem"
                        :dataKey="'id_number'"
                        :selectionMode="'single'"
                        :rowaction="true"
                        :rowdelete="true"
                        :headers="itemHeaders" 
                        :headerVisible="false"
                        :rows="items" /> 
                    </div>
                  </div>
                </Fieldset>
                <div>
                    <FormInputText wrapperClass="field col-12" label="Comentarios superiores" />
                    <FormInputText wrapperClass="field col-12" label="Comentarios inferiores" />
                </div>
              </Panel>
        </div>
    </div>
</template>

<script>
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import FormInputText from '../../../components/general/FormInputText.vue'
import FormCalendar from '../../../components/general/FormCalendar.vue'
import FormInputNumber from '../../../components/general/FormInputNumber.vue'
import Loader from "../../../components/general/Loader.vue"
import { Order } from "../../../models/comercial/Order";
import { OrderItem } from "../../../models/comercial/OrderItem";
import Session from "../../../mixins/sessionMixin";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';
import { Customer } from '../../../models/comercial/Customer';
import { LAB } from '../../../models/compras/LAB';
import Status from '../../../components/general/Status.vue';
import { PaymentCondition } from "../../../models/comercial/PaymentCondition";


import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
    ErrorToast,
} from "../../../utilities/General";

export default{
    mixins: [Session],
    props: {
    id: null,
    modal: null,
  },
    data(){
        return{
            open: false,
            entity: new Order(),
            entities: [],
            customers: [],
            labs:[],
            payments:[],
            suppliers:[],
            loading: false,
            productDialog: false,
            customer: new Customer(),
            item: new OrderItem(),

            orderRequestHelper: {
            visible: false,
            },
            labHelper:{
              visible: false,
            },
            paymentHelper:{
              visible: false,
            },
            rules: [
                new Rule({ name: "id_customer" }),
                new Rule({ name: "order_date"  }),
                new Rule({ name: "id_lab" })
            ],
            validate: {
                valid: false,
                validations: {
                    id_customer: null,
                    order_date: null,
                    id_lab: null
                },
            },
            itemHeaders: [
                new HeaderGrid("SKU", "key_name"),
                new HeaderGrid("Cantidad", "quantity"),
                new HeaderGrid("Description", "description"),
                new HeaderGrid("Precio", "price", { type: 'currency' }),
                new HeaderGrid("IVA", "iva", { type: 'percentage'}),
                new HeaderGrid("IEPS", "ieps", { type: 'percentage'}),
              new HeaderGrid("Total", "total", { type: 'currency', function: 'SUM' }),
            ],
            orderHeaders:[
              new HeaderGrid("Cliente", "name"),
              new HeaderGrid("Fecha","order_date", {type: "date"}),
              new HeaderGrid("Dirección Entrega","labAddress")
            ],
            labHeaders:[
              new HeaderGrid('Nombre','name'),
              new HeaderGrid('Direccion','address')
            ],
            paymentHeaders:[
              new HeaderGrid('Nombre','name'),
              new HeaderGrid('Días','days')
            ]
      };
    },
    components: {HelperDialog,BasicFormToolbar,FormInputText,FormCalendar,FormInputNumber,Loader,BasicDatatable, Status},
    created() {
        this.entity = new Order(this.session);
      },
      async mounted() {
        await this.refresh();
      },
    computed: {
      items() {
        return this.entity.items.filter(x => (x.is_deleted ?? false) == false);
        },
      status(){
      if (this.entity.status == 1) {
        return {
          severity: 'primary',
          status: 'Registrado',
          image: "processing.png"
        } 
      } else if (this.entity.status == 2) {
        return {
          severity: 'primary',
          status: 'Requerido',
          image: "afected.png"
        } 
      } else if(this.entity.status == 3) {
        return {
          severity: 'primary',
          status: 'Ajustado',
          image: "afected.png"
        } 
      } else if(this.entity.status == 9){
        return{
          severity: 'primary',
          status: 'Cancelado',
          image: "afected.png"
        }
      } else {
        return {
          severity: 'primary',
          status: 'Registrado',
          image: "processing.png"
        } 
      }
    }
  },
    methods: {
        async openTable(){
          this.entities = await this.entity.all();
          this.open = true;
          console.log("hola")
        },
        openNew() {
          this.entity = new Order(this.session);
        },

        deleteItem(payload) {
            payload.is_deleted = true;
          },

        selectOrder(payload){
          console.log('payload', payload)
          this.entity = fillObject(this.entity, payload);
          this.entity.order_date = new Date(payload.order_date);
          this.entity.status = payload.status;
        },

        selectLab(payload){
          console.log('payload',payload);
          this.entity.id_lab = payload.id;
          this.entity.labAddress = payload.address;
        },

        selectPayment(payload){
          this.entity.payment_condition = payload.days;
        },
            
        async save() {
          try {
            //* Validacion de form
            this.loading = true;
            this.validate = validate(this.entity, this.rules);
            if (!this.validate.valid) {
            throw "Favor de validar los campos";
            }              
            //* Si el id es != 0 entonces actualizamos
            if (this.entity.id && this.entity.id > 0) {
              //* Actualizamos
              let entity = await this.entity.update();
              this.$toast.add({
                severity: "success",
                summary: "Actualizar",
                detail: "Informacion actualizada con exito",
                life: 3000,
              });
              this.$emit('update', entity);
              //* Modificamos el listado pah
              let index = this.entities.findIndex((x) => x.id == this.entity.id);
              this.entities[index] = entity;
            } else {
              //* Creamos uno nuevo
              let entity = await this.entity.save();
              //* Agregamos un dato extra

              this.entities.push(entity);
              this.entities = null;
            }
            
            this.entity = new Order(this.session);
            this.entities = null;
            if (this.open == true){
              this.entities = await this.entity.all();
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },

        async refresh() {
          this.loading = true;
          try {
            if (this.id) {
                    this.entity.id = this.id;
                    let retrieved = await this.entity.retrieve();
                    this.selectOrder(retrieved);
                  }
            if (!this.modal) 
              this.entities = await this.entity.all();
              this.customers = await new Customer(this.session).all();
              this.labs = await new LAB(this.session).all();
              this.payments = await new PaymentCondition(this.session).all();
              if (this.id) {
              this.entity.id = this.id;
              let entity = await this.entity.retrieve();
              this.entity = fillObject(this.entity, entity);
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        }
      }
    }
</script>

<style scoped lang="scss">
</style>