import {
    Abstract
} from './Abstract';

export class OrderItem extends Abstract{
    constructor(session) {
        super('comercial/OrderItem',session);
        this.id_number = null;
        this.key_name = null;
        this.id_request = null;
        this.id_concept = null;
        this.description = null;
        this.quantity = null;
        this.price = null;
        this.iva = null;
        this.ieps = null;
        this.total = null;
    }
}