import {
    Abstract
} from './Abstract';

import axios from 'axios';
export class Order extends Abstract{
    constructor(session) {
        super('comercial/Order',session);
        this.order_date = new Date();
        this.id_customer = null;
        this.id_lab = null;
        this.id_oc = null;
        this.id_payment_condition = null;
        this.discount1 = 0;
        this.discount2 = 0;
        this.discount3 = 0;
        this.shipment_data = null;
        this.freight_data = null;
        this.comment_inf = null;
        this.comment_sup = null;
        this.status = null;
        this.currency = null;
        this.exchange_rate = null;
        this.subtotal = null;
        this.total = null;
        this.items = [];
        this.quotation = [];
        this.id_crm_quotation = null;
    }


    async cancelar() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
        console.log('Viva Mexico:');
        let response = await axios.put(this.controller + '/cancelar/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}