import {
    Abstract
} from './Abstract';

export class PaymentCondition extends Abstract {
    constructor(session) {
        super('comercial/PaymentCondition', session);
        this.name = null;
        this.key_name = null;
        this.days = null;
    }
}
